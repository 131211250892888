import React, { useState } from "react";
import { Script, withPrefix } from "gatsby";
import "./src/styles/global.css";
import "./static/vendor/css/bundle.min.css";
import "./static/vendor/css/jquery.fancybox.min.css";
import "./static/vendor/css/owl.carousel.min.css";
import "./static/vendor/css/swiper.min.css";
import "./static/vendor/css/cubeportfolio.min.css";
import "./static/vendor/css/wow.css";
import "./static/vendor/css/LineIcons.min.css";
import "./static/classic-business/css/settings.css";
import "./static/classic-business/css/style.css";
import "./static/classic-business/css/custom.css";

const WrapElement = ({ element }) => {
  const [loaded, setLoaded] = useState(false);
  const [revLoaded, setRevLoaded] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  return (
    <>
      {element}
      <Script
        src={withPrefix("../vendor/js/bundle.min.js")}
        onLoad={() => setLoaded(true)}
      />
      {loaded && (
        <>
          <Script src={withPrefix("../vendor/js/jquery.appear.js")} />
          <Script src={withPrefix("../vendor/js/jquery.fancybox.min.js")} />
          <Script src={withPrefix("../vendor/js/owl.carousel.min.js")} />
          <Script src={withPrefix("../vendor/js/swiper.min.js")} />
          <Script
            src={withPrefix("../vendor/js/jquery.cubeportfolio.min.js")}
          />
          <Script src={withPrefix("../vendor/js/wow.min.js")} />
          <Script
            src={withPrefix("../vendor/js/jquery.themepunch.tools.min.js")}
          />
          <Script
            src={withPrefix("../vendor/js/jquery.themepunch.revolution.min.js")}
            onLoad={() => setRevLoaded(true)}
          />
          {revLoaded && (
            <>
              <Script
                src={withPrefix(
                  "../vendor/js/extensions/revolution.extension.actions.min.js"
                )}
              />
              <Script
                src={withPrefix(
                  "../vendor/js/extensions/revolution.extension.carousel.min.js"
                )}
              />
              <Script
                src={withPrefix(
                  "../vendor/js/extensions/revolution.extension.kenburn.min.js"
                )}
              />
              <Script
                src={withPrefix(
                  "../vendor/js/extensions/revolution.extension.layeranimation.min.js"
                )}
              />
              <Script
                src={withPrefix(
                  "../vendor/js/extensions/revolution.extension.migration.min.js"
                )}
              />
              <Script
                src={withPrefix(
                  "../vendor/js/extensions/revolution.extension.navigation.min.js"
                )}
              />
              <Script
                src={withPrefix(
                  "../vendor/js/extensions/revolution.extension.parallax.min.js"
                )}
              />
              <Script
                src={withPrefix(
                  "../vendor/js/extensions/revolution.extension.slideanims.min.js"
                )}
              />
              <Script
                src={withPrefix(
                  "../vendor/js/extensions/revolution.extension.video.min.js"
                )}
                onLoad={() => setAllLoaded(true)}
              />
              {allLoaded && (
                <Script src={withPrefix("../classic-business/js/script.js")} />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export const wrapPageElement = ({ element }) => {
  return <WrapElement element={element} />;
};
